 .static-megamenu {
    @apply lg:px-5;
    .menu-main-wrapper {
        @apply bg-white fixed w-screen h-screen pr-[1.5625rem] top-0 left-0 overflow-y-auto transform -translate-x-full transition-transform opacity-0 flex flex-col lg:block lg:w-auto lg:transform-none lg:opacity-100 lg:transition-none lg:static lg:h-auto lg:overflow-y-visible z-50 lg:pr-0 lg:bg-transparent;
        :global {
            .MenuWrapper {
                @apply pb-5;
                &:has(.menu-item.level-1) {
                    background-color: rgba(213, 235, 247, 0.25);
                    @apply flex flex-col;
                }
                > .menu-item.level-1 {
                    &:first-child {
                        @apply bg-white pb-2 order-first;
                        .AccordionToggle {
                            @apply pointer-events-none;
                        }
                        .select-category-wrapper {
                            @apply hidden;
                        }
                        .subcat-items {
                            > .menu-item.level-2 {
                                @apply pl-6 mb-0;
                                .menu-item {
                                    @apply py-0 text-left w-full text-xl leading-[3.375rem] font-extrabold text-blue-1 uppercase relative block tracking-normal;
                                    &:after {
                                        background-image: url('data:image/svg+xml,<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.744 9.07814L1.51467 15.6575C1.152 16.0168 0.597333 16.0168 0.256 15.6575C-0.0853333 15.2982 -0.0853333 14.6919 0.256 14.3326L5.86667 8.42694L0.256 2.52125C-0.0853333 2.13952 -0.0853333 1.55568 0.256 1.1964C0.597333 0.837121 1.152 0.837121 1.51467 1.1964L7.744 7.75329C8.08533 8.13503 8.08533 8.71886 7.744 9.07814Z" fill="black"/></svg>');
                                        @apply content-[''] bg-no-repeat bg-contain w-2 h-4 absolute top-4 right-[0.1875rem] z-0;
                                    }
                                    &[href] {
                                        &:after {
                                            @apply hidden;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:has(strong.menu-title-2) {
                        @apply order-first;
                    }
                    a.levelTop {
                        &:has(.no-submenu) {
                            @apply block py-0 px-6 w-full text-left text-xl leading-[3.375rem] font-extrabold text-blue-1 tracking-normal capitalize;
                        }
                        &:has(.menu-title-2) {
                            @apply py-0 px-6 pointer-events-none;
                        }
                    }
                }
            }
            strong.menu-title, strong.menu-title-2 {
                @apply text-gold-1 text-xs leading-[0.875rem] font-bold uppercase tracking-[0.03125rem];
            }
            strong.menu-title-2 {
                @apply block pt-[2.3125rem] pb-1.5;
            }
        }
    }
    &.open {
        .menu-main-wrapper {
            @apply translate-x-0 opacity-100;
        }
    }
    :global {
        .menu-container {
            @apply flex-grow flex flex-col lg:flex-grow-0 lg:flex-row;
            div[class *= "row_contained"] {
                @apply max-w-full;
            }
            &:has(.tab-container) {
                .select-category-wrapper {
                    .sub-category-top.level-1-heading {
                        @apply mt-3 mb-4;
                    }
                }
            }
            &:has(.menu-2-banner) {
                .select-category-wrapper {
                    .sub-category-top.level-1-heading {
                        @apply pl-[3.5625rem] mt-2;
                    }
                }
            }
            &:has(.menu-4-banner) {
                .select-category-wrapper {
                    .sub-category-top.level-1-heading {
                        @apply pl-[3.5625rem] mt-[0.4375rem];
                    }
                }
                .subcat-items {
                    > .menu-item.level-2:has(.menu-title) {
                        @apply mb-8;
                    }
                }
            }
        }
        .menu-item {
            &.active {
                .subCat {
                    @apply lg:visible lg:opacity-100 lg:transform lg:transition lg:duration-300;
                }
                > div:not(.subCat) {
                    a {
                        @apply lg:text-blue-1 lg:cursor-pointer;
                        &:after {
                            @apply lg:w-full lg:opacity-100;
                        }   
                    }
                }
            }
            > div:not(.subCat) {
                a {
                    @apply block pb-3.5 pt-[0.8125rem] uppercase text-xs text-grey-1 leading-[0.875rem] tracking-[0.03125rem] font-bold box-border relative z-50;
                    &:after {
                        @apply hidden lg:block content-[''] absolute bottom-[0.5625rem] left-0 w-0 border-t border-solid border-blue-1 opacity-0 transition-all duration-200 ease-in-out;
                    }
                }
            }
            a {
                @apply no-underline;
            }
            &:nth-child(2) {
                @apply lg:mr-8;
                > div:not(.subCat) {
                    @apply lg:relative lg:flex lg:items-center;
                    &:after {
                        @apply hidden pointer-events-none lg:block content-[''] absolute w-[0.0625rem] h-[1.125rem] bg-grey-1 -right-8;
                    }
                }
            }
        }
        .subCat {
            @apply hidden lg:block lg:opacity-0 lg:invisible lg:absolute lg:top-full lg:bg-white lg:inset-x-0;
            @screen lg {
                box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);
            }
        }
        .MenuWrapper {
            @apply flex-grow lg:p-0 lg:flex lg:flex-wrap lg:justify-center lg:gap-x-8 lg:gap-y-2;
            .levelTop {
                @apply inline-block lg:p-0;
            }
        }
        .AccordionToggle {
            @apply block px-6 w-full text-left;
            &:not(:has(.menu-title)) {
                @apply relative;
                &:after {
                    background-image: url('data:image/svg+xml,<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.744 9.07814L1.51467 15.6575C1.152 16.0168 0.597333 16.0168 0.256 15.6575C-0.0853333 15.2982 -0.0853333 14.6919 0.256 14.3326L5.86667 8.42694L0.256 2.52125C-0.0853333 2.13952 -0.0853333 1.55568 0.256 1.1964C0.597333 0.837121 1.152 0.837121 1.51467 1.1964L7.744 7.75329C8.08533 8.13503 8.08533 8.71886 7.744 9.07814Z" fill="black"/></svg>');
                    @apply content-[''] bg-no-repeat bg-contain w-2 h-4 absolute top-5 right-[1.6875rem] z-0;
                }
            }
            span.levelTop {
                &:has(.menu-title) {
                    @apply leading-[0.875rem] mt-1 mb-2.5;
                }
                @apply text-xl leading-[3.375rem] font-extrabold text-blue-1;
            }
            &:has(strong.menu-title), &:has(strong.menu-title-2) {
                @apply pointer-events-none;
                &:after {
                    @apply hidden;
                }
            }
            &:has(.no-submenu) {
                @apply capitalize;
                &:after {
                    @apply hidden;
                }
            }
        }
        .select-category-wrapper {
            &:has(.level-1-heading .menu-title) {
                    ~ .subcat-items {
                        > .menu-item.level-2 {
                            @apply pl-6 mb-0;
                            .menu-item {
                                @apply text-left w-full text-xl leading-[3.375rem] font-extrabold text-blue-1 uppercase relative block;
                                &:after {
                                    background-image: url('data:image/svg+xml,<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.744 9.07814L1.51467 15.6575C1.152 16.0168 0.597333 16.0168 0.256 15.6575C-0.0853333 15.2982 -0.0853333 14.6919 0.256 14.3326L5.86667 8.42694L0.256 2.52125C-0.0853333 2.13952 -0.0853333 1.55568 0.256 1.1964C0.597333 0.837121 1.152 0.837121 1.51467 1.1964L7.744 7.75329C8.08533 8.13503 8.08533 8.71886 7.744 9.07814Z" fill="black"/></svg>');
                                    @apply content-[''] bg-no-repeat bg-contain w-2 h-4 absolute top-4 right-[0.1875rem] z-0;
                                }
                                &[href] {
                                    &:after {
                                        @apply hidden;
                                    }
                                }
                            }
                        }
                        > .banner {
                            @apply hidden;
                        }
                    }
            }
        }
        .sub-category-top {
            @apply text-left px-6 w-full relative;
            &.level-1-heading {
                @apply mt-[0.0625rem] mb-1.5;
                &:not(:has(.menu-title)) {
                    @apply pl-[3.875rem] mb-3.5;
                }
                .select-category-item {
                    &:not(:has(.menu-title)) {
                        @apply text-blue-1 text-xl leading-10 font-extrabold normal-case;
                    }
                }
            }
            &.level-2-heading {
                @apply mt-2 mb-3 pl-[3.875rem];
                .select-category-item {
                    @apply text-blue-1 text-base leading-10 font-extrabold uppercase;
                }
            }
            .back-icon {
                @apply absolute left-6 top-3;
            }
        }
        .subcat-items {
            @apply flex-grow flex flex-col;
            > .menu-item  {
                @apply px-6;
                &:has(.level-2-heading) {
                    @apply leading-[1.0625rem];
                }
                .menu-item {
                    @apply text-left w-full text-xl leading-[3.375rem] font-extrabold text-blue-1 uppercase;
                    &:has(.level-2-heading) {
                        @apply text-sm leading-[1.0625rem];
                    }
                }
                &.level-2, &.level-3 {
                    @apply mb-[0.9375rem] pl-[3.875rem];
                    &:has(.menu-title) {
                        @apply leading-[0.875rem];
                    }
                    &:has(a .level-2-heading) {
                        @apply mb-2.5;
                    }
                    &:has(a .no-link) {
                        @apply mb-1.5;
                    }
                    &:has(a svg) {
                        @apply mb-6;
                    }
                    a {
                        @apply text-sm leading-6 font-medium text-secondary-2;
                        &:has(.level-2-heading) {
                            @apply pointer-events-none text-blue-1 leading-[1.0625rem] font-extrabold;
                        }
                        &:has(.menu-title) {
                            @apply leading-[0.875rem] pointer-events-none;
                        }
                        &:has(.no-link) {
                            @apply pointer-events-none text-xs leading-[1.125rem] text-grey-1 normal-case;
                        }
                        &:has(svg) {
                            @apply text-xs leading-[1.125rem] text-grey-1 font-bold;
                        }
                        svg {
                            @apply mr-1;
                            &, + span {
                                @apply inline;
                            }
                        }
                    }
                    .view-all-link {
                        @apply inline-block align-top relative pb-0.5 text-blue-1 text-xs leading-[0.875rem] font-bold no-underline uppercase tracking-[0.03125rem] hover:no-underline;
                        &:after {
                            @apply content-[''] absolute inset-x-0 bottom-0 z-0 border-b border-solid border-current;
                        }
                    }
                }
            }
            span {
                @apply block;
            }
            > .banner {
                @apply bg-grey-1 pb-16 flex-grow;
                div[class*="banner_wrapper"], div.wrapper {
                    div[class*="banner_content"] {
                        @apply w-full py-[3.8125rem] pl-6 pr-4;
                    }
                }
                .banner-inner-content {
                    .btn-primary {
                        @apply mt-8;
                    }
                    h2.banner-heading {
                        @apply font-primary font-bold text-4xl leading-[2.75rem] tracking-[0.03125rem];
                    }
                }
                .menubanner {
                    &:last-child:not(:first-child) {
                        div[class*="banner_wrapper"], div.wrapper {
                            div[class*="banner_content"] {
                                @apply py-[2.1875rem] px-5;
                            }
                        }
                        .banner-inner-content {
                            p {
                                @apply max-w-[14.375rem] mx-auto leading-[1.4375rem];
                                &, strong {
                                    @apply font-extrabold;
                                }
                            }
                            .btn-primary {
                                @apply mt-5;
                            }
                        }
                    }
                    &.menu-1-banner {
                        &:last-child:not(:first-child) {
                            .banner-inner-content {
                                p {
                                    @apply max-w-[13.25rem];
                                }
                            }
                        }
                    }
                    &.menu-2-banner {
                        div[class*="banner_wrapper"], div.wrapper {
                            div[class*="banner_content"] {
                                @apply py-[4.5rem] px-[2.1875rem];
                            }
                        }
                        .banner-inner-content {
                            .btn-primary {
                                @apply mt-[0.8125rem];
                            }
                        }
                        &:last-child:not(:first-child) {
                            .banner-inner-content {
                                .btn-primary {
                                    @apply mt-5 px-9;
                                }
                            }
                        }
                    }
                    &.menu-3-banner {
                        div[class*="banner_wrapper"], div.wrapper {
                            div[class*="banner_content"] {
                                @apply pt-[3.3125rem] pl-[2.6875rem] pb-[5.25rem] pr-5;
                            }
                        }
                        .banner-inner-content {
                            h2 {
                                @apply max-w-[13.75rem];
                            }
                            .btn-primary {
                                @apply mt-[1.125rem] ml-1;
                            }
                        }
                        &:last-child:not(:first-child) {
                            .banner-inner-content {
                                .btn-primary {
                                    @apply mt-5 px-10 ml-0;
                                }
                            }
                        }
                    }
                    &.menu-4-banner {
                        div[class*="banner_wrapper"], div.wrapper {
                            div[class*="banner_content"] {
                                @apply pt-[6.0625rem] pl-2.5 pb-[4.5rem] pr-1;
                            }
                        }
                        .banner-inner-content {
                            .btn-primary {
                                @apply mt-[2.125rem] -ml-2.5;
                            }
                        }
                    }
                }
                .menu-quick-links {
                    @apply flex flex-col gap-y-[2.375rem] pt-9 pb-[1.875rem] px-[3.6875rem];
                    li {
                        @apply leading-[0.875rem];
                        a {
                            @apply inline-flex w-full gap-x-3 text-blue-1 text-xs leading-[0.875rem] font-bold underline uppercase tracking-[0.03125rem];
                            span {
                                @apply flex-grow;
                            }
                        }
                    }
                }
                &:has(.menu-1-banner) {
                    @apply mt-[0.6875rem];
                }
            }
            .banner-column {
                @apply bg-white;
                .tab-container {
                    @apply relative mb-[1.375rem];
                    .tab-header {
                        @apply pt-0.5 pb-0 pl-[3.875rem] pr-6 flex justify-between items-center gap-x-2.5;
                        &:after {
                            background-image: url('data:image/svg+xml,<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.744 9.07814L1.51467 15.6575C1.152 16.0168 0.597333 16.0168 0.256 15.6575C-0.0853333 15.2982 -0.0853333 14.6919 0.256 14.3326L5.86667 8.42694L0.256 2.52125C-0.0853333 2.13952 -0.0853333 1.55568 0.256 1.1964C0.597333 0.837121 1.152 0.837121 1.51467 1.1964L7.744 7.75329C8.08533 8.13503 8.08533 8.71886 7.744 9.07814Z" fill="black"/></svg>');
                            @apply content-[''] bg-no-repeat bg-contain w-2 h-4 transform -rotate-90 transition-transform duration-200;
                        }
                    }
                    .tab-title {
                        @apply text-sm leading-[1.0625rem] font-extrabold uppercase;
                    }
                    .tab-radio {
                        @apply inset-y-0 left-[3.75rem] right-5 w-full h-full;
                        &:checked {
                            ~ .tab-header {
                                &:after {
                                    @apply rotate-90;
                                }
                            }
                        }
                    }
                    .tab-content {
                        @apply relative z-1  pl-[3.875rem] pr-[1.3125rem];
                        ul {
                            @apply flex flex-col gap-5 pt-5 pr-7;
                            &.usgb-link {
                                @apply pr-10;
                            }
                            li {
                                a {
                                    @apply text-sm leading-6 text-secondary-2 font-medium;
                                    &.view-all-link {
                                        @apply inline-block align-top relative pb-0.5 text-blue-1 text-xs leading-[0.875rem] font-bold no-underline uppercase tracking-[0.03125rem] hover:no-underline;
                                        &:after {
                                            @apply content-[''] absolute inset-x-0 bottom-0 z-0 border-b border-solid border-current;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:has(.tab-container) {
                    .pb-text-root + .menubanner {
                        @apply mt-5;
                    }
                }
                .contact-us-wrapper {
                    @apply pt-9 pb-4 pl-[3.75rem] pr-9;
                    .level-2-heading {
                        @apply text-blue-1 text-sm leading-[1.0625rem] uppercase font-extrabold mb-[0.9375rem];
                        strong {
                            @apply font-extrabold;
                        }
                    }
                    .call-sales-links {
                        @apply mb-5;
                    }
                    ul {
                        li {
                            @apply leading-[1.125rem] mb-3 text-grey-1;
                            > span {
                                @apply text-grey-1 text-xs leading-[1.125rem] font-medium -tracking-[0.015625rem];
                            }
                            > a {
                                @apply text-grey-1 inline-flex items-center w-full gap-x-2;
                                > svg { 
                                    @apply text-grey-1;
                                }
                                > span {
                                    @apply flex-grow text-grey-1 text-xs leading-[1.125rem] font-bold;
                                }
                            }
                        }
                    }
                }
            }
        }
        .hamburger-icon {
            @apply lg:hidden inline-block cursor-pointer absolute top-auto bottom-[1.125rem] left-[1.875rem] h-[0.9375rem] w-[1.125rem] z-50;
            svg {
                @apply text-white;
            }
        }
        .top-menu {
            @apply lg:overflow-y-auto;
            @screen lg {
                max-height: calc(100vh - 6.5625rem);
            }
            &:not(.no-submenu) {
                &:after {
                    @apply hidden lg:block content-[''] absolute top-full h-screen inset-x-0 -z-1 pointer-events-none bg-black bg-opacity-[0.37];
                }
            }
            div[class *= "columnGroup_root"] {
                > div[class *= "columnGroup_root"] {
                    &:first-child:not(:last-child) {
                        @apply lg:max-w-[90rem] lg:mx-auto;
                    }
                    &:last-child {
                        @apply relative;
                    }
                }
            }
            > div:not([class *= "columnGroup_root"]) > .pb-text-root {
                .menu-title {
                    @apply lg:max-w-[90rem] lg:mx-auto;
                }
            }
            .menu-title {
                @apply font-primary font-bold text-xs leading-[0.875rem] text-gold-1 uppercase tracking-[0.03125rem];
                strong {
                    @apply font-bold;
                }
            }
            .list-heading {
                @apply mb-3;
                > a, > strong, > span {
                    @apply lg:text-sm lg:leading-6 xl:text-base xl:leading-[1.6875rem] uppercase font-extrabold font-primary text-blue-1;
                }
                > a {
                    @apply no-underline hover:underline;
                }
            }
            .list-content {
                li {
                    @apply mb-5;
                    > a, > span {
                        @apply text-secondary-2 lg:text-xs lg:leading-[1.125rem] xl:text-sm xl:leading-6 font-primary font-medium;
                        &.view-all-link {
                            @apply relative pb-0.5 text-blue-1 text-xs leading-[0.875rem] font-bold no-underline uppercase tracking-[0.03125rem] hover:no-underline hover:text-hover;
                            &:after {
                                @apply content-[''] absolute inset-x-0 bottom-0 z-0 border-b border-solid border-current;
                            }
                        }
                    }
                    > a {
                        @apply no-underline hover:underline;
                    }
                    svg {
                        @apply inline mr-1;
                    }
                }
            }
            .menu-bottom-block {
                @apply lg:flex-row lg:items-center lg:justify-center #{!important};
                @apply lg:gap-x-10 lg:pt-5 lg:pb-[1.3125rem] lg:px-4 lg:max-w-[90rem] lg:mx-auto;
                &:before {
                    @apply hidden lg:block content-[''] absolute bg-inherit inset-0 z-0;
                }
                > div {
                    @apply lg:relative lg:z-1;
                }
                p {
                    &, strong {
                        @apply font-extrabold;
                    }
                }
            }
            div[class *= "banner_root"] {
                &, div[class*="banner_wrapper"], div.wrapper {
                    @apply h-full;
                }
            }
            .banner-inner-content {
                h2 {
                    @apply lg:text-4xl lg:leading-[3rem] xl:text-[2.5rem] xl:leading-[3.25rem];
                }
            }
        }
        .five-column-megamenu {
            div[class *= "columnGroup_root"] {
                > div[class *= "columnGroup_root"] {
                    &:first-child:not(:last-child) {
                        @apply grid #{!important};
                        @apply lg:pl-5 xl:pl-8 2xl:pl-[2.5625rem];
                        @screen lg {
                            grid-template-columns: 15% 15% 15% 20% 35%;
                        }
                        @screen xl {
                            grid-template-columns: 16% 17% 17% 20% 30%;
                        }
                        @screen 2xl {
                            grid-template-columns: 18.3% 18.5% 18.3% 17.37% 27.53%;
                        }
                    }
                }
            }
            .pagebuilder-column.menu-column {
                @apply w-auto #{!important};
                &:not(:last-child) {
                    @apply lg:pr-5 xl:pr-8 2xl:pr-10;
                }
                &:last-child {
                    @apply -mt-[4.375rem];
                }
            }
            .menu-title {
                @apply lg:pt-8 lg:pb-6 lg:pl-5 xl:pl-8 2xl:pl-[2.5625rem] lg:pr-[35%] xl:pr-[30%] 2xl:pr-[24.0625rem];
            }
            .top-space {
                @apply mt-2.5;
                &-more {
                    @apply mt-7;
                }
            }
            div[class*="banner_wrapper"], div.wrapper {
                div[class*="banner_content"] {
                    @apply lg:px-5 lg:py-16 xl:pt-[4.25rem] xl:pb-[4.75rem] xl:pl-10;
                }
            }
            .banner-inner-content {
                .btn-primary {
                    @apply lg:px-2.5 lg:w-full lg:mt-4;
                }
            }
        }
        .two-column-megamenu {
            .pagebuilder-column.menu-column {
                @apply lg:flex-grow;
                &:first-child {
                    @apply lg:pt-[1.875rem] lg:pb-2 lg:pl-10 lg:pr-5;
                }
                &:last-child {
                    @apply lg:max-w-[53.625rem];
                }
            }
            .menu-title {
                @apply lg:mb-[1.375rem];
            }
            .list-heading {
                @apply lg:mb-5;
                > a, > strong, > span {
                    @apply lg:text-sm lg:leading-[1.0625rem];
                }
            }
            .list-content {
                @apply lg:max-w-[12.5625rem];
                li {
                    > a, > span {
                        @apply lg:text-sm lg:leading-6 text-secondary-2;
                    }
                }
            }
            picture[class*="banner_overlay"] {
                &:not([class*="banner_posterOverlay"]) {
                    ~ div[class*="banner_content"] {
                        @apply lg:max-w-xl;
                    }
                }
            }
            div[class*="banner_wrapper"], div.wrapper {
                @apply justify-start;
                div[class*="banner_content"] {
                    @apply lg:pt-[5.9375rem] lg:pl-8 xl:pl-10 2xl:pl-[4.4375rem];
                }
            }
            .banner-inner-content {
                .btn-primary {
                    @apply lg:w-full lg:max-w-[14.0625rem] lg:mt-[0.3125rem];
                }
            }
            .menu-bottom-block {
                > div[class *= "buttons_root"] {
                    @apply lg:min-w-[15.125rem];
                }
            }
        }
        .three-column-megamenu {
            > div:not([class *="columnGroup_root"]) > .pb-text-root {
                .menu-title {
                    @apply lg:pl-5 xl:pl-10 lg:pr-[61%] lg:pt-[1.875rem] lg:pb-[1.4375rem] 2xl:pr-[52.5rem];
                }
            }
            .pagebuilder-column.menu-column {
                @apply flex-grow;
                &:first-child {
                    @apply lg:pl-5 xl:pl-10;
                }
                &:not(:last-child) {
                    @apply lg:pr-5;
                }
                &:last-child {
                    @apply lg:-mt-[5.0625rem] xl:-mt-[4.1875rem] lg:max-w-[53.625rem];
                }
                .list-heading {
                    @apply lg:mb-4;
                    > a, > strong, > span {
                        @apply lg:text-sm lg:leading-6;
                    }
                }
                div[class*="banner_wrapper"], div.wrapper {
                    div[class*="banner_content"] {
                        @apply lg:pt-20 lg:pb-24 lg:pl-14 xl:pt-[5.9375rem] xl:pb-[6.5625rem] xl:pl-[4.5rem] lg:pr-5;
                    }
                }
                .banner-inner-content {
                    .btn-primary {
                        @apply lg:mt-10 xl:mt-14 lg:w-full lg:max-w-[14rem];
                    }
                }
            }
            .menu-bottom-block {
                > div[class *= "buttons_root"] {
                    @apply lg:min-w-[14.5rem];
                }
            }
            &.our-company-menu {
                .pagebuilder-column.menu-column {
                    &:not(:last-child) {
                        @apply lg:pt-[1.875rem] lg:pb-[1.625rem] lg:px-5 xl:pl-10;
                    }
                    &:first-child {
                        .list-heading {
                            @apply lg:mb-4;
                        }
                    }
                    &:nth-child(2) {
                        @apply xl:pl-[2.8125rem];
                        .list-heading {
                            @apply lg:mb-[0.6875rem];
                        }
                        .list-content {
                            li {
                                @apply lg:mb-[0.8125rem];
                                &, > a, > span {
                                    @apply lg:text-xs lg:leading-[1.125rem];
                                }
                                > a {
                                    @apply lg:font-bold hover:no-underline;
                                }
                                > span {
                                    @apply lg:-tracking-[0.015625rem];
                                }
                            }

                        }
                    }
                    .top-space-more {
                        @apply lg:mt-8 xl:mt-[2.6875rem];
                    }
                    div[class*="banner_wrapper"], div.wrapper {
                        div[class*="banner_content"] {
                            @apply lg:pt-16 xl:pt-[5.625rem] lg:px-5 lg:pb-9 lg:w-full;
                        }
                    }
                    .banner-inner-content {
                        h2.banner-heading {
                            @apply font-primary font-bold tracking-[0.03125rem] xl:text-4xl xl:leading-[3.25rem];
                        }
                        .btn-primary {
                            @apply lg:mt-[1.8125rem] lg:w-full lg:max-w-[16.25rem];
                        }
                    }
                }
                .menu-title {
                    @apply lg:mb-[1.375rem];
                }
                .list-heading {
                    > a, > strong, > span {
                        @apply lg:text-sm lg:leading-6;
                    }
                }
                .menu-bottom-block {
                    @apply lg:pt-[1.875rem] lg:pb-[1.6875rem] lg:px-5 xl:pl-[2.1875rem] xl:pr-[2.125rem];
                }
            }
            .menu-quick-links {
                @apply flex flex-wrap items-center justify-center lg:gap-y-2.5 lg:gap-x-4 xl:gap-x-7 2xl:gap-x-[2.4375rem] leading-[0.875rem];
                li {
                    @apply leading-[0.875rem];
                    a {
                        @apply inline-flex gap-x-2.5 items-center text-blue-1 text-xs leading-[0.875rem] font-bold no-underline uppercase tracking-[0.03125rem] hover:no-underline;
                        span {
                            @apply underline;
                        }
                        &:hover span {
                            @apply no-underline;
                        }
                    }
                }
            }
        }
    }
    > div[class*="menu-container"] {
        @apply hidden lg:flex;
    }
    .megamenu-header-mobile {
        @apply sticky top-0 z-[51] flex items-start justify-between py-[1.0625rem] pl-5 pr-[1.1875rem] bg-white lg:hidden;
        .close-btn {
            svg {
                @apply mt-[0.1875rem] mr-[0.0625rem];
            }
            &:after {
                @apply content-[''] absolute inset-y-0 -right-[1.5625rem] h-screen w-[1.5625rem] bg-[#6D6D6D] z-50;
            }
        }
        + div[class*="menu-container"] {
            @apply flex;
        }
    }
    .pushmenu-links {
        @apply bg-blue-1 h-full text-white pt-[1.4375rem] pl-[1.4375rem] pr-5 pb-16 lg:hidden;
        li {
            @apply leading-[1.125rem] mb-[1.4375rem];
            &.welcome-message, a, .avatarButton {
                @apply text-white text-sm font-extrabold leading-[1.125rem] capitalize font-primary no-underline hover:no-underline;
            }
            .link {
                @apply leading-6 font-medium ml-[1.625rem] no-underline hover:no-underline;
            }
            span[aria-hidden] {
                @apply mr-3.5;
            }
            &.welcome-message, &.account-link {
                @apply mb-4;
            }
            &.logout-link {
                @apply mb-6;
            }
        }
        :global {
            .header-contact-us-links {
                @apply flex flex-col;
                li {
                    @apply block #{!important};
                    a {
                        svg {
                            @apply inline align-middle mr-1.5;
                        }
                        span {
                            @apply align-middle;
                        }
                    }
                    &.phone-link {
                        @apply order-last;
                    }
                    &.chat-link {
                        @apply order-first;
                    }
                    span.mobile-only {
                        @apply inline #{!important};
                    }
                } 
            }
            .btn-link {
                @apply text-current;
            }
        }
        .welcome-message {
            @apply flex items-center mb-4;
        }
    }
}
